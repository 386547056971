<template>
  <f7-page class="foryou-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <HomeNavigationComponent search="foryou" />
    </template>
    <section class="foryou-container">
      <div class="container">
        <div v-if="isGettingList" class="preloader-cont">
          <f7-preloader></f7-preloader>
        </div>

        <div v-if="productList.length" class="recommendation-container">
          <template v-for="(product, i) in productList" :key="'rc_' + i">
            <RecommendationCardComponent :data="product" :f7router="f7router" />
          </template>
        </div>

        <NoDataFoundComponent v-if="!isGettingList && productList.length == 0" :wine="true" :size="'sm'" type="full" :title="$t.getTranslation('LBL_NO_PRODUCT_FOUND')" />
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, computed, inject, defineAsyncComponent } from "vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";

// import HomeNavigationComponent from "@/components/navigations/HomeNavigationComponent.vue";
// import RecommendationCardComponent from "@/components/cards/RecommendationCardComponent.vue";
// import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

const HomeNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "home-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/HomeNavigationComponent.vue"));
const RecommendationCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "recommendation-card" */ /* webpackMode: "lazy" */ "@/components/cards/RecommendationCardComponent.vue"));
const NoDataFoundComponent = defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue"));

export default defineComponent({
  name: "ForYouPage",
  components: {
    NoDataFoundComponent,
    HomeNavigationComponent,
    RecommendationCardComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup() {
    const store = useStore();
    
    let isGettingList = ref(true);
    const productList = ref([]);
    const showPreloader = ref(false);
    const $t = inject("$translation");
    const user = computed(() => store.getters["user/getData"]);

    let page = 1;
    let size = 30;
    let lastPage = 1;

    const getProductList = async (isDefault) => {
      let obj = {
        page: page,
        size: size,
        LanguageCode: $t.getLanguage(),
        mode: "FORYOU",
      };
      if (isDefault) {
        obj["IsDefaultForYou"] = 1;
      }
      let ret = await get("/mobile/product/public/list", obj);
      isGettingList.value = false;

      if (ret?.data.length == 0) {
        //recall the function to call isdefault function incase there's a 0 return
        await getProductList(true);
        return;
      }

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          productList.value.push(item);
        }
        lastPage = ret?.lastPage;
        page++;
      }
    };

    const loadMore = () => {
      return lastPage >= page ? getProductList() : false;
    };

    onMounted(() => {
      getProductList();
    });

    return { productList, isGettingList, loadMore, showPreloader };
  },
});
</script>

<style scoped>
.bg-gray {
  width: 100%;
  min-height: 735px;
}
</style>
